export const HUMAINS_EMAIL = "humains@24heures.org";
export const COMMUNICATION_EMAIL = "communication@24heures.org";
export const SG_EMAIL = "secretaire.general@24heures.org";
export const CTMA_EMAIL = "jeuneetcon@24heures.org";
export const SECURITE_EMAIL = "securite@24heures.org";
export const LOGISTIQUE_EMAIL = "logistique@24heures.org";
export const LOG_ELEC_EMAIL = "logelec@24heures.org";
export const SIGNALETIQUE_EMAIL = "signaletique@24heures.org";
export const BUREAU_EMAIL = "bureau@24heures.org";
export const DSI_EMAIL = "dsi@24heures.org";
